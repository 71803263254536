@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import url("https://fonts.googleapis.com/css2?family=Anton&family=Inter&family=Lato:ital,wght@1,300&family=Nunito:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

body {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  color: white;
  min-height: 100vh;
}

.textbox {
  background: none;
  border-bottom: 1px solid;
  padding-top: 20px;
  color: #b1b1b1;
  font-size: 1em;
  width: 100%;
}

.textbox-text {
  padding-top: 10px;
  color: #b1b1b1;
  width: 100%;
  font-size: 0.7em;
  letter-spacing: 0.2em;
}

.textbox-kginput::placeholder {
  font-size: 1em;
  letter-spacing: 0.2em;
  color: #565656;
}

.custom-phone-input {
  background: none;
  border-bottom: 1px solid;
  padding-top: 20px;
  color: #b1b1b1;
  font-size: 1em;
  width: 100%;
}

.custom-phone-input input[type="tel"] {
  outline: none;
  background: none;
}

.custom-phone-input > div {
  padding-left: 5px;
  padding-right: 5px;
}

.custom-phone-input input[type="tel"]::placeholder {
  font-size: 0.7em;
  letter-spacing: 0.2em;
}

.textbox-questionnaire {
  padding-top: 10px;
  background: none;
  border-bottom: 1px solid;
  color: #b1b1b1;
  font-size: 1em;
  width: 100%;
}

.textbox-text-questionnaire {
  color: #b1b1b1;
  width: 100%;
  font-size: 0.7em;
  letter-spacing: 0.2em;
}

.textbox-questionnaire::placeholder {
  font-size: 0.7em;
  letter-spacing: 0.2em;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

.textbox::placeholder {
  font-size: 0.7em;
  letter-spacing: 0.2em;
}

.main-button-gradient {
  background: linear-gradient(96deg, #9bf2c0 1.49%, #91bdf6 103.49%);
  box-shadow:
    0px 14px 14px 0px rgba(0, 0, 0, 0.09),
    0px 2.5px 4px 0px rgba(0, 0, 0, 0.15);
}

input:focus {
  outline: none;
}

input:focus::placeholder {
  color: transparent;
}

.glow-green {
  color: rgba(255, 255, 255, 1);
  box-shadow: 0 5px 15px rgb(155, 242, 192, 0.4);
}

.glow-purple {
  color: rgba(255, 255, 255, 1);
  box-shadow: 0 5px 15px rgb(214, 182, 240, 0.4);
}

.comments {
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
}

.hero {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../public/assets/achievements-bg.png");
  background-size: cover;

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    opacity: 1;
  }
}

.perfect-week {
  height: 18px;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 0.5px solid #9d8956;
  background: linear-gradient(
    107deg,
    #f4ebb1 4.32%,
    #caab5c 22.9%,
    #f4ebb2 42.85%,
    #cbad5e 62.54%,
    #f2e8ae 85.59%,
    #c2982c 115.29%
  );
}

.achievements-bg {
  background-image: url("../public/assets/elipse.svg");
  fill: #f07df3;
  opacity: 0.7;
  mix-blend-mode: plus-lighter;
  filter: blur(17px);
}

button:active {
  background-color: rgba(61, 61, 61, 0.3);
}

.counter {
  font-family: "Anton", sans-serif;
}

.gradient-text {
  background: linear-gradient(96deg, #fff 5.01%, #757575 98.91%);
  background-clip: text;
  -webkit-background-clip: text;
  display: inline-block;
  color: transparent;
}

.metallic-gradient {
  background: linear-gradient(
    92deg,
    #999 -4.6%,
    #616161 5.26%,
    #323232 15.12%,
    #818181 24.98%,
    #e7e7e7 34.84%,
    #848484 44.7%,
    #474747 54.56%,
    #c2c2c2 64.42%,
    #fff 74.27%,
    #242424 84.75%,
    #b7b7b7 93.99%
  );
}

.timeline-tags span {
  border-radius: 4px;
  border: 0.5px solid var(--New-Gray, rgba(255, 255, 255, 0.23));
  background: rgba(59, 59, 59, 0.06);
  backdrop-filter: blur(17px);
  letter-spacing: -0.36px;
  padding: 2px 8px;
}

.mealInfoBoxGradient {
  background: rgba(95, 95, 95, 0.3);
  background-blend-mode: luminosity;
  backdrop-filter: blur(37.5px);
}

.workout-gradient-text {
  background: linear-gradient(96deg, #fff 5.01%, #757575 98.91%);
  background-clip: text;
  display: inline-block;
  color: transparent;
}

.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Change this value to the number of lines you want */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.anton {
  color: var(--White, #fff);
  font-family: "Anton", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 32.781px;
  text-transform: uppercase;
  line-height: 45.59px;
  text-align: center;
}

.anton-black {
  color: #000;
  /* Black color */
  font-family: "Anton", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 32.781px;
  text-transform: uppercase;
  line-height: 45.59px;
  text-align: center;
}

.anton-grey {
  color: #808080;
  /* Black color */
  font-family: "Anton", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 32.781px;
  text-transform: uppercase;
  line-height: 45.59px;
  text-align: center;
}

.purple-gradient {
  background: var(
    --Gradient-purple,
    linear-gradient(95deg, #d6b6f0 2.94%, #7e87ef 96.92%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.purple-gradient-background {
  background: var(
    --Gradient-purple,
    linear-gradient(95deg, #d6b6f0 2.94%, #7e87ef 96.92%)
  );
}

.main-gradient-text {
  display: inline-block;
  color: transparent;
  background: linear-gradient(96deg, #9bf2c0 1.49%, #91bdf6 103.49%);
  background-clip: text;
  -webkit-background-clip: text;
}

.workout-gradient-button {
  background: linear-gradient(96deg, #fff 5.01%, #757575 98.91%);
}

.lifestyle-gradient-button {
  background: var(
    --Gradient-purple,
    linear-gradient(95deg, #d6b6f0 2.94%, #848ce9 74.36%)
  );
  /* Button shadow dark */
  box-shadow:
    0px 2.5px 4px 0px rgba(0, 0, 0, 0.15),
    0px 14px 14px 0px rgba(0, 0, 0, 0.09);
}

.marketplace-gradient-button {
  background: var(
    --Gradient-purple,
    linear-gradient(95deg, #d6b6f0 2.94%, #848ce9 74.36%)
  );
}

.main-gradient-text {
  display: inline-block;
  color: transparent;
  background: linear-gradient(96deg, #9bf2c0 1.49%, #91bdf6 103.49%);
  background-clip: text;
  -webkit-background-clip: text;
}

.continueButton {
  position: fixed;
  bottom: 2em;
  border-radius: 12px;
  width: 90%;
  padding: 14px 10px;
  font-weight: 600;
  color: #000000;
}

.line {
  border: 1px solid white;
  width: 90%;
  height: 0px;
}

.multichoice {
  border-radius: 12px;
  background: rgba(61, 61, 61, 0.3);
  padding: 1em 0;
  color: #d6b6f0;
  margin: 0.6em 0;
  font-size: 18px;
}

.main-cta {
  background-color: rgba(61, 61, 61, 0.3);
  border-radius: 12px;
  border: 0.5px solid #444;
  height: 3em;
  font-size: 1.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
}

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.card {
  flex: 0 0 auto;
}

.main-stat {
  height: 5em;
  background-color: rgba(61, 61, 61, 0.3);
  padding: 0.5em;
  text-align: center;
}

.tags span {
  border-radius: 6px;
  /* font-size: 0.75rem; */
  line-height: 1rem;
  font-weight: 600;
}

.main-stat h4 {
  color: var(--Light-gray, #b1b1b1);
  font-size: 8px;
  letter-spacing: 3px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.main-stat span {
  font-size: 1.5rem;
  line-height: 2rem;
}

.main-section {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  padding: 0.5em;
  width: 358px;
  height: 98px;
  border-radius: 12px;
  margin: 1em 0;
}

.payment-history {
  border-radius: 24px;
  border: 0.5px solid rgba(255, 255, 255, 0.4);
  background: rgba(95, 95, 95, 0.3);
  background-blend-mode: luminosity;
  backdrop-filter: blur(37.5px);
}

.profile-heading {
  background: var(
    --Green-purple-gradient,
    linear-gradient(96deg, #9bf2c0 1.49%, #91bdf6 103.49%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.profile-program-pw {
  border-radius: 4px;
  border: 0.5px solid #9d8956;
  background: linear-gradient(
    107deg,
    #f4ebb1 4.32%,
    #caab5c 22.9%,
    #f4ebb2 42.85%,
    #cbad5e 62.54%,
    #f2e8ae 85.59%,
    #c2982c 115.29%
  );
}

.profile-program-box {
  border-radius: 12px;
  border: 0.5px solid #383838;
  background: linear-gradient(180deg, #171717 0%, #0f0f0f 100%);
}

.profile-program-heading {
  background: linear-gradient(
    74deg,
    #999 -132.76%,
    #616161 -107.92%,
    #323232 -83.07%,
    #818181 -58.23%,
    #e7e7e7 -33.39%,
    #848484 -8.55%,
    #474747 16.3%,
    #c2c2c2 41.14%,
    #fff 65.98%,
    #242424 92.38%,
    #b7b7b7 115.67%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.leaderboard-gradient-text {
  background: var(
    --Gradient-purple,
    linear-gradient(95deg, #d6b6f0 2.94%, #7e87ef 96.92%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.border-gradient {
  border-image: var(
    --Gradient-purple,
    linear-gradient(95deg, #d6b6f0 2.94%, #7e87ef 96.92%)
  );
}

.leaderboard-user-list-item {
  border-radius: 12px;
  border: 1.5px solid var(--Light-purple, #d6b6f0);
  background: rgba(61, 61, 61, 0.3);
  mix-blend-mode: screen;
  padding-top: 3px;
  padding-bottom: 3px;
}

/* Just a little test for a project */
.countdown__wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: content-fit;
}

.feature-popup-wrapper {
  border-radius: 11.782px;
  border: 0.736px solid rgba(126, 135, 239, 0.27);
  background: var(--White, #fff);
  color: black;
  font-family: "SF Pro Rounded";
  padding: 10px;
}

.countdown {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 300px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex: 0 0 auto;
  text-align: center;

  &:after {
    display: block;
    width: 100%;
    text-align: center;
    content: "";
    padding: 1rem;
  }
}

.countdown .countdown__box {
  box-sizing: border-box;
  overflow: hidden;
  height: 8rem;

  /* border-radius: 0.2rem; */
  /* border: 1px solid black; */
  /* width: calc(50% - 1px); */
  color: white;
  /* background-color: black; */
  /* box-shadow: 0 5px 10px 2px rgba(black, 0.1); */

  /* &:first-child {
    margin-left: 40px;
  } */

  /* &:last-child {
    margin-right: 35px;
  } */
}

.countdown__inner span {
  display: block;
  width: 100%;
  height: 8rem;
  font-size: 4rem;
  font-family: "Anton", sans-serif;
  background: linear-gradient(180deg, #fff 0%, #d6b6f0 100%);
  -webkit-background-clip: text;
  /* WebKit/Blink browsers */
  background-clip: text;
  color: transparent;
  /* Make the text transparent so that the gradient shows through */
  line-height: 1;
  padding: 2rem 0.5rem;
  /* background-image: linear-gradient(to bottom, rgba(white, 0.2), black); */
}

.countdown__inner__sec {
  display: block;
  width: 100%;
  height: 5rem;
  font-size: 3rem;
  font-family: "Anton", sans-serif;
  background: linear-gradient(180deg, #fff 0%, #d6b6f0 100%);
  -webkit-background-clip: text;
  /* WebKit/Blink browsers */
  background-clip: text;
  color: transparent;
  /* Make the text transparent so that the gradient shows through */
  line-height: 1;
  padding: 0.5rem;
  /* background-image: linear-gradient(to bottom, rgba(white, 0.2), black); */
}

.countdown__seconds
  .countdown__box:not(:last-child):nth-child(1)
  .countdown__inner
  > :not(:only-child) {
  --base-duration: 3s;
  --index: 0;
  animation-name: a-countdown-6;
  animation-iteration-count: 1;
  animation-direction: forwards;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  /* Calculate animation duration based on child index */
  animation-duration: calc(var(--base-duration) - (var(--index) * 0.5s));
  /* Adjust the factor as needed */
}

.countdown__seconds
  .countdown__box:not(:last-child):nth-child(2)
  .countdown__inner
  > :not(:only-child) {
  --base-duration: 3s;
  --index: 1;
  animation-name: a-countdown-6;
  animation-iteration-count: 1;
  animation-direction: forwards;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  /* Calculate animation duration based on child index */
  animation-duration: calc(var(--base-duration) - (var(--index) * 0.5s));
  /* Adjust the factor as needed */
}

.countdown__seconds
  .countdown__box:not(:last-child):nth-child(3)
  .countdown__inner
  > :not(:only-child) {
  --base-duration: 3s;
  --index: 2;
  animation-name: a-countdown-6;
  animation-iteration-count: 1;
  animation-direction: forwards;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  /* Calculate animation duration based on child index */
  animation-duration: calc(var(--base-duration) - (var(--index) * 0.5s));
  /* Adjust the factor as needed */
}

.countdown__seconds
  .countdown__box:not(:last-child):nth-child(4)
  .countdown__inner
  > :not(:only-child) {
  --base-duration: 3s;
  --index: 3;
  animation-name: a-countdown-6;
  animation-iteration-count: 1;
  animation-direction: forwards;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  /* Calculate animation duration based on child index */
  animation-duration: calc(var(--base-duration) - (var(--index) * 0.5s));
  /* Adjust the factor as needed */
}

.countdown__seconds
  .countdown__box:not(:last-child):nth-child(5)
  .countdown__inner
  > :not(:only-child) {
  --base-duration: 3s;
  --index: 4;
  animation-name: a-countdown-6;
  animation-iteration-count: 1;
  animation-direction: forwards;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  /* Calculate animation duration based on child index */
  animation-duration: calc(var(--base-duration) - (var(--index) * 0.5s));
  /* Adjust the factor as needed */
}

.countdown__seconds
  .countdown__box:not(:last-child):nth-child(6)
  .countdown__inner
  > :not(:only-child) {
  --base-duration: 3s;
  --index: 5;
  animation-name: a-countdown-6;
  animation-iteration-count: 1;
  animation-direction: forwards;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  /* Calculate animation duration based on child index */
  animation-duration: calc(var(--base-duration) - (var(--index) * 0.5s));
  /* Adjust the factor as needed */
}

.countdown__seconds .countdown__box:last-child .countdown__inner {
  animation-name: a-countdown-10;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-direction: forwards;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}

/* The animation logic is created by SaurabhSahu01 :_D */
@keyframes a-countdown-6 {
  0%,
  5% {
    transform: translateY(0);
  }

  95%,
  100% {
    transform: translateY(-8rem);
  }
}

@keyframes a-countdown-10 {
  0%,
  5% {
    transform: translateY(0);
  }

  95%,
  100% {
    transform: translateY(-8rem);
  }
}

.bg-box-mm {
  background-color: #ffffff; /* Solid white color */
  border: 1px solid #dcdcdc; /* Slight border to separate from background */
}

/* Components -> WeeklyWorkoutReport.js */
.weekly-workout-consistency {
  border-radius: 12px;
  border: 0.5px solid #383838;
  background: linear-gradient(180deg, #171717 0%, #0f0f0f 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.weekly-workout-consistency-heading {
  color: var(--Light-gray, #b1b1b1);
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 8px;
  font-style: normal;
  font-weight: 510;
  line-height: normal;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.wwc-weeks {
  color: #545454;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 9.333px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.purple-text-gradient {
  background: var(
    --Gradient-purple,
    linear-gradient(95deg, #d6b6f0 2.94%, #848ce9 74.36%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.wwc-report-indicator {
  display: inline-flex;
  padding: 1.512px 6.049px;
  justify-content: center;
  align-items: center;
  border-radius: 3.024px;
  border: 0.378px solid var(--Red, #fa5757);
}

.wwc-report-text {
  color: var(--Red, #fa5757);
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 9.073px;
  font-style: normal;
  font-weight: 590;
  line-height: normal;
  letter-spacing: -0.272px;
  text-transform: capitalize;
}

.wwc-score {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  /* 125% */
  background: var(
    --Green-purple-gradient,
    linear-gradient(96deg, #9bf2c0 1.49%, #91bdf6 103.49%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.wwc-suggestion-text {
  color: #545454;

  /* Card body */
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 9.333px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.wwc-suggested-count {
  background: var(
    --Green-purple-gradient,
    linear-gradient(96deg, #9bf2c0 1.49%, #91bdf6 103.49%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.wwc-chart-container {
  width: auto;
  height: auto;
}

@keyframes barstyle {
  from {
    height: 0px;
  }

  to {
    height: var(--calculated-height);
  }
}

.barStyle {
  animation-name: barstyle;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* loader css */
.loading svg polyline {
  fill: none;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.loading svg polyline#back {
  fill: none;
  stroke: #5ecc7b33;
}

.loading svg polyline#front {
  fill: none;
  stroke: #5ecc7b;
  stroke-dasharray: 48, 144;
  stroke-dashoffset: 192;
  animation: dash_682 1.4s linear infinite;
}

@keyframes dash_682 {
  72.5% {
    opacity: 0;
  }

  to {
    stroke-dashoffset: 0;
  }
}

.custom-textarea {
  outline: none;
  border: 1px solid #7e87ef;
  box-sizing: border-box;
}

.custom-textarea:focus {
  border: 1px solid #3d3d3d;
}

.custom-textarea::placeholder {
  color: #565656;
}

/* Monthly Wrapped CSS styles */
.bar-chart-style {
  border-radius: 22.291px;
  border: 0.697px solid rgba(255, 255, 255, 0.12);
  background: linear-gradient(
    180deg,
    rgba(35, 35, 35, 0.4) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  background-blend-mode: luminosity;
  box-shadow:
    65.48px 167.184px 50.155px 0px rgba(0, 0, 0, 0.01),
    41.796px 106.928px 45.976px 0px rgba(0, 0, 0, 0.04),
    23.684px 60.256px 38.661px 0px rgba(0, 0, 0, 0.15),
    10.449px 26.819px 28.561px 0px rgba(0, 0, 0, 0.26),
    2.786px 6.618px 15.673px 0px rgba(0, 0, 0, 0.29);
}

.bar-chart-text {
  font-size: 16.544px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.331px;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.background-gray-gradient {
  background: linear-gradient(180deg, #171717 0%, #0f0f0f 100%);
}

.purple-white-gradient {
  font-family: "Anton", sans-serif;
  background: linear-gradient(180deg, #fff 0%, #d6b6f0 100%);
  -webkit-background-clip: text;
  /* WebKit/Blink browsers */
  background-clip: text;
  color: transparent;
  /* Make the text transparent so that the gradient shows through */
  /* background-image: linear-gradient(to bottom, rgba(white, 0.2), black); */
}

.picker-container {
  width: 100%;
  height: 168px; /* 7 items visible, with the middle one centered */
  overflow-y: scroll;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  position: relative;
  text-align: center;
  box-sizing: border-box;
}

.picker-container::-webkit-scrollbar {
  display: none;
}

.picker-item {
  font-size: 14px;
  height: 24px;
  color: #929292;
  transition:
    font-size 0.2s,
    color 0.2s;
}

.picker-item.selected {
  font-size: 16px;
  font-weight: bold;
  background-color: rgba(116, 116, 128, 0.2);
  border-radius: 6px;
}

.picker-item.adjacent {
  transform: scaleY(0.8);

  font-size: 16px;
  color: #929292;
}

.picker-item.far {
  transform: scaleY(0.6);

  font-size: 16px;
  color: #929292;
}

.picker-item.veryfar {
  transform: scaleY(0.5);

  font-size: 16px;
  color: #929292;
}
