.userTypeDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    cursor: pointer;
    border: 1px solid black;
    border-radius: 10px;
    padding: 10px;
}