.dashboardContainer {
  margin: 20px;
}

.pieAnalyticsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.chartContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border: 1px solid #ccc;
  padding: 20px;
}

.dataPoints {
  flex: 1;
}

.pieChart {
  flex: 1;
}

/* Styling for average-stay div */
.average-stay {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Styling for MonthYearTable */
.tableContainer {
  border: 1px solid #ccc;
  padding: 20px;
  margin-top: 20px;
}

.tableHeader {
  font-weight: bold;
  margin-bottom: 10px;
}

.tableRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.tableRow span {
  flex: 1;
  text-align: center;
}
