/* FundManagementForm.css */

.fund-form, .coach-tools-form  {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.fund-form label {
    font-weight: bold;
    margin-bottom: 5px;
}

.fund-form .form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
    justify-content: space-between;
}

.fund-form .form-group:not(:last-child) {
    margin-bottom: 20px;
    /* Add extra space between groups */
}

.fund-form .form-group-buttons {
    display: flex;
    gap: 10px;
}

.fund-form .form-group-inner {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
}

.fund-form .membership-header {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.fund-form .form-control {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.fund-form .btn-primary {
    background-color: #007bff;
    border: none;
    margin-top: 15px;
    padding: 10px 20px;
    width: 100px;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.fund-form .btn-primary:hover {
    background-color: #0056b3;
}
