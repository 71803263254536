.detailSection {
    width: 75pc;
    margin: auto;
    padding: 10px;
}

.detailSectionHeader {
    display: flex;
    justify-content: space-between;
    background-color: whitesmoke;
    padding: 10px;
    cursor: pointer;
}

.detailSectionHeader span {
    font-size: large;
    font-weight: 600;
}

.detailSectionBody {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin: 5px 0 0 0;

}

.detailSectionBody span {
    margin: 0;
}