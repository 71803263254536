.pieChartContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #f7f7f7;
}

.sectionHeader {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.sectiondetail {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin-top: 10px;
}

.sectionDetailData {
    flex: 1;
    padding: 10px;
}

.sectionDetailData div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* Align items vertically */
    margin-bottom: 5px;
}

.sectionDetailData .colorBox {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border: 1px solid #000;
    border-radius: 50%;
    vertical-align: middle;
}

.sectionDetailData .dataInfo {
    display: flex;
    align-items: center;
}

.sectionDetailData .dataValue {
    margin-left: 5px;
    /* Add a gap between title and value */
}


.sectionDetailData span:first-child {
    font-weight: bold;
}

.sectionDetailData span:last-child {
    margin-left: 5px;
    /* Add a gap between title and value */
}

.sectionDetailPie {
    flex: 1;
    padding: 10px;
    min-width: 300px;
    max-width: 50%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .sectiondetail {
        flex-direction: column;
    }

    .sectionDetailData,
    .sectionDetailPie {
        border: none;
        padding: 10px 0;
        width: 100%;
        max-width: none;
    }

    .sectionDetailPie {
        padding: 10px;
        min-width: auto;
    }
}

.colorBox {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 10px;
    border: 1px solid #000;
    border-radius: 50pc;
    vertical-align: middle;
}